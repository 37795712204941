import styled from 'styled-components'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { ModalProps } from 'antd/es/modal'
import { Modal, Select } from 'antd'
import {
  palette,
  mediaQueries,
  letterSpacing,
} from '~components/homepage/styled/constants'

import { DarkButton } from './shared'

const { Option } = Select

export const StyledModal = styled(Modal)<ModalProps>`
  font-size: 13px;
  text-align: center;
  margin-top: 40px !important;
  margin-bottom: 20px !important;

  .ant-modal-close-x button {
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
  }

  input,
  textarea {
    max-width: 393px;
    width: 100%;
    border: 1px solid ${palette.inputBorder};
    border-radius: 0px;
    color: ${palette.darkBlue};
    outline: none;
    background: white;
    font-size: 12px;
    padding: 20px;
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    box-shadow: 0 0 0 30px white inset !important;

    @media (max-width: ${mediaQueries.small}) {
      max-width: 100%;
    }
  }

  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    input:focus,
    input:active,
    textarea:focus,
    textarea:active {
      font-size: 16px;
    }
  }

  @media screen and (device-aspect-ratio: 2/3) {
    input:focus,
    input:active,
    textarea:focus,
    textarea:active {
      font-size: 16px;
    }
  }

  /* Fix Input Zoom on iPhone 5, 5C, 5S, iPod Touch 5g */
  @media screen and (device-aspect-ratio: 40/71) {
    input:focus,
    input:active,
    textarea:focus,
    textarea:active {
      font-size: 16px;
    }
  }

  /* Fix Input Zoom on iPhone 6, iPhone 6s, iPhone 7  */
  @media screen and (device-aspect-ratio: 375/667) {
    input:focus,
    input:active,
    textarea:focus,
    textarea:active {
      font-size: 16px;
    }
  }

  /* Fix Input Zoom on iPhone 6 Plus, iPhone 6s Plus, iPhone 7 Plus, iPhone 8, iPhone X, XS, XS Max  */
  @media screen and (device-aspect-ratio: 9/16) {
    input:focus,
    input:active,
    textarea:focus,
    textarea:active {
      font-size: 16px;
    }
  }

  .ant-select {
    width: 100%;

    .ant-select-selection {
      display: block;
      height: auto;
      -webkit-box-shadow: 0 0 0 30px white inset !important;
      box-shadow: 0 0 0 30px white inset !important;
      border: 1px solid ${palette.inputBorder};
      border-radius: 0px;
      color: ${palette.darkBlue};
      outline: none;
      background: white;
      font-size: 12px;
      padding: 15px 20px;
      text-align: left;
      line-height: initial;

      .ant-select-selection__rendered {
        line-height: initial;
        margin: 0 auto;
        width: 105%;
        z-index: 10;
      }
    }
  }

  .knife-select {
    border-top: 1px solid #aaa7a1 !important;

    ul li {
      font-size: 12px;
      display: flex;
      align-items: center;

      &.ant-select-dropdown-menu-item {
        padding: 0px;

        &:hover {
          background: #f7f5f1;

          div.knife-counter {
            background: #f7f5f1;
          }
        }
      }

      &.ant-select-dropdown-menu-item-active {
        background: #f7f5f1;
      }

      span {
        position: relative;
        top: 2px;
      }
    }
  }

  .ant-select-dropdown {
    top: 47px !important;
    border-radius: 0px;
    border: 1px solid ${palette.inputBorder};
    box-shadow: none;
    border-top: none;

    width: 100%;
    max-width: 383px;

    .ant-select-dropdown-menu {
      max-height: initial;
    }
  }

  .ant-select-open + svg {
    transform: rotateX(180deg);
  }

  textarea {
    min-height: 120px;
  }

  input {
    height: 48px;
  }

  label {
    color: ${palette.darkBlue};
    text-align: left;
    display: inline-block;
    width: 100%;
    padding-bottom: 2px;
  }

  .textarea-section {
    margin-top: 15px;
  }

  .send-button {
    margin-top: 15px;
  }

  .ant-modal-body {
    background-color: ${palette.beige};
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
    padding: 20px 25px;
    padding-top: 10px;
    text-align: center;

    p {
      margin-bottom: 0;
      line-height: 26px;
    }

    .title {
      font-size: 11px;
      color: ${palette.darkBlue};
      max-width: 560px;
      width: 100%;
      margin: 0 auto;
      font-family: 'Averta-Bold', sans-serif;
    }

    .title-light {
      color: ${palette.darkBiege};
    }

    .title-thin {
      font-family: 'Averta-Regular', sans-serif;
    }

    .gift-card-title {
      font-size: 14px;
      text-align: left;
      margin-bottom: 4px;
      margin-top: 20px;
    }

    .header {
      font-size: 14px;
      color: ${palette.darkBlue};
      font-family: 'Averta-Bold', sans-serif;
      margin-bottom: 18px;
    }

    .sub-title,
    .sub-title-store {
      max-width: 333px;
      width: 100%;
      font-size: 10px;
      margin: 0 auto 40px;
    }

    .sub-title-store {
      margin: 0 auto 10px;
      font-size: 13px;
      max-width: 420px;
    }

    .sub-title-store.bold {
      font-family: 'Averta-Bold', sans-serif;
    }

    .form-grid {
      div:first-child {
        padding-right: 10px;
      }

      div:last-child {
        padding-left: 10px;
      }

      @media (max-width: ${mediaQueries.small}) {
        & {
          div:first-child,
          div:last-child {
            padding: inherit;
          }
        }
      }
    }

    .error-sign {
      position: absolute;
      right: 17px;
      top: 16px;
      opacity: 0;
      transition: 0.2s;

      &.active {
        opacity: 1;
      }
    }

    .footer {
      max-width: 440px;
      margin: 35px auto 0;
      font-size: 10px;
      color: ${palette.darkBiege};
    }
  }
`

export const Link = styled.a`
  color: ${palette.darkBiege};
  text-decoration: underline;

  :hover {
    color: ${palette.darkBiege};
  }
`

export const StyledAnchorLink = styled(AnchorLink)`
  color: ${palette.darkBiege};
  font-family: 'Averta-Bold', sans-serif;

  :hover {
    color: ${palette.darkBiege};
  }
`

export const FieldRow = styled.div`
  max-width: 590px;
  margin: 0 auto;
  margin-top: 16px;
  margin-bottom: 16px;
  gap: 20px;

  display: grid;
  grid-template-columns: 35fr 65fr;

  @media (max-width: ${mediaQueries.small}) {
    display: block;
  }
`

interface FieldItemProps {
  top?: boolean
  bold?: boolean
  color?: string
  fs?: string
}

export const FieldItem = styled.div<FieldItemProps>`
  position: relative;
  text-align: left;
  font-family: ${({ bold = false }: FieldItemProps): string =>
      bold ? "'Averta-Bold'" : "'Averta-Regular'"},
    sans-serif;
  color: ${({ color = '' }: FieldItemProps): string => color || 'inherit'};
  font-size: ${({ fs = '' }: FieldItemProps): string => fs || '12px'};
  display: flex;
  align-items: center;

  &.order-knife-wrapper {
    & > .error-sign {
      right: 37px;
      top: 15px;
      z-index: 1;
    }
  }
`

interface FieldLabelProps {
  top?: boolean
  bold?: boolean
  color?: string
  fs?: string
}

export const FieldLabel = styled.div<FieldLabelProps>`
  display: flex;
  align-items: ${({ top = false }: FieldLabelProps): string =>
    top ? 'flex-start' : 'center'};
  justify-content: flex-end;
  text-align: right;
  padding-top: ${({ top = false }: FieldLabelProps): string =>
    top ? '16px' : 'auto'};
  font-family: ${({ bold = false }: FieldLabelProps): string =>
      bold ? "'Averta-Bold'" : "'Averta-Regular'"},
    sans-serif;
  color: ${({ color = '' }: FieldLabelProps): string => color || '#1D3041'};
  font-size: ${({ fs = '' }: FieldLabelProps): string => fs || '13px'};

  @media (max-width: ${mediaQueries.small}) {
    display: block;
    text-align: left;
  }

  .nowrap {
    white-space: nowrap;
  }
`

interface SelectProps {
  width?: string
}

export const SelectWrapper = styled.div<SelectProps>`
  display: inline-block;
  position: relative;
  max-width: ${(props: SelectProps): string =>
    props.width ? '393px' : '210px'};
  width: 100%;
  height: 48px;

  @media (max-width: ${mediaQueries.small}) {
    max-width: 100% !important;
  }

  .ant-select-selection__placeholder {
    color: ${palette.darkBiege};
  }

  .ant-select-selection-selected-value {
    padding-right: 30px !important;
  }

  &.generic-dropdown {
    .ant-select-selection__rendered {
      z-index: 10;
      width: 105%;
    }

    .ant-select-dropdown-menu {
      max-height: initial;
      padding-top: 0px;
      padding-bottom: 0px;

      max-width: 100% !important;

      .material-price {
        text-align: right;
      }

      .material-name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      li {
        padding: 13px 12px;
        border-top: 1px solid ${palette.inputBorder};

        &.ant-select-dropdown-menu-item-active {
          background: ${palette.beige};
        }

        display: grid;
        grid-template-columns: 3fr 1fr;

        &:hover {
          background: ${palette.beige};
        }
      }
    }
  }

  & > svg {
    position: absolute;
    right: 13px;
    top: 52%;
    transform: translateY(-50%);
    transition: 0.3s;
    transform-origin: 50% 20%;
  }

  .icon-option {
    svg {
      height: 100%;
      height: 20px;
      width: 100%;
    }
  }
`

export const KnifeOption = styled(Option)`
  padding: 0px;
`

export const KnifeCounter = styled.div`
  z-index: 99;
  height: 22px;
  overflow: hidden;

  padding-right: 20px;
  padding-left: 10px;

  transition: 0.3s;

  div {
    margin: 0 22px;
    display: inline-block;
    top: -3px;
    position: relative;
  }

  .counter-item {
    top: 0px;
    height: 15px;
    display: inline-block;
  }

  svg {
    transition: 0.2s;
    &:hover {
      transform: scale(1.1);
    }
  }

  @media (max-width: 360px) {
    padding-right: 0px;
    padding-left: 10px;
    position: absolute;
    right: 10px;
    background-color: #fff;

    div {
      margin: 0 10px;
    }
  }
`

export const KnifeName = styled.div`
  flex: 1;
  display: flex;
  align-items: center;

  padding: 10px 18px;

  .check {
    margin-right: 10px;
    z-index: 10;
    opacity: 0;
  }

  svg.visible {
    opacity: 1;
    transition: 0.3;
  }

  .empty-check {
    position: absolute;
    width: 15px;
    height: 15px;
    border: 1px solid ${palette.inputBorder};
    z-index: 9;
  }
`

export const OrderedKnife = styled.div`
  max-width: 330px;
  width: 100%;
  display: flex;
  margin-bottom: 3px;

  span {
    margin-left: auto;
  }

  @media (max-width: ${mediaQueries.small}) {
    max-width: 100%;
  }
`

export const AddToOrderButton = styled(DarkButton)`
  margin-right: 20px;
  margin-bottom: 20px;
  margin-top: 8px;
  float: right;
  max-width: 170px;
  width: auto;
  text-align: center;
  font-family: 'Averta-Semibold', sans-serif;
  letter-spacing: ${letterSpacing};
  color: ${palette.beige};

  &:hover {
    background: transparent;
    color: ${palette.darkBlue};
    border-color: ${palette.darkBlue};
  }
`

export const SendOrderButton = styled(DarkButton)`
  max-width: 208px;
  text-align: center;
  width: 100%;
  color: ${palette.beige};
  font-family: 'Averta-Semibold', sans-serif;
  letter-spacing: ${letterSpacing};

  @media (max-width: ${mediaQueries.small}) {
    max-width: 100%;
  }

  &:hover {
    background: transparent;
    color: ${palette.darkBlue};
    border-color: ${palette.darkBlue};
  }
`

export const RedirectToStoreWrapper = styled('div')`
  padding: 0px 60px;

  @media (max-width: ${mediaQueries.small}) {
    padding: 0px 20px;
  }
`
